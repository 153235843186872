<template>
    <content-loader class="pt-2" viewBox="0 0 80 140" :speed="2" primaryColor="#babdb6" secondaryColor="#ecebeb">
	    <rect x="0" y="5" rx="3" ry="3" width="80" height="70" /> 
        <rect x="0" y="84" rx="3" ry="3" width="80" height="8" /> 
        <rect x="0" y="100" rx="3" ry="3" width="80" height="8" /> 
        <rect x="0" y="116" rx="3" ry="3" width="80" height="8" /> 
        <rect x="0" y="132" rx="3" ry="3" width="80" height="8" /> 
    </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
    components : {
        ContentLoader
    }
}
</script>

<style>

</style>