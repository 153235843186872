<template>
	<Checkout></Checkout>
</template>

<script>
import Checkout from '@/components/Checkout/Checkout.vue';
export default {
	components : {
		Checkout
	}
}
</script>

<style>

</style>