<template>
    <div>
        <Header></Header>
        <router-view v-if="isRouterLoading"/>
        <base-spinner v-else></base-spinner>
        <Footer></Footer>
        <button v-on:click="showKartDialog" v-if="isAuth" type="button" class="mb-5 me-5 btn rounded-pill position-fixed bottom-0 end-0" style="z-index:1"><h6 class="m-2">Checkout</h6></button>
        <button v-else v-on:click="showSigninDialog" type="button" class="mb-5 me-5 btn rounded-pill position-fixed bottom-0 end-0" style="z-index:1"><span>Sign In</span></button>
        <Kart :show="kartDialog" @cancel="cancel"></Kart >
        <Signin :show="signinDialog" @cancel="cancel"></Signin>
    </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Kart from '@/components/Kart.vue';
import Signin from '@/components/Signin.vue';
export default {
    data(){
        return {
            kartDialog : false,
            // signinDialog: !this.isAuth
        }
    },
    components: {
        Header,
        Footer,
        Kart,
        Signin
    },
    computed: {
        signinDialog(){
            console.log(this.isAuth);
            return !this.isAuth;
        },
		isAuth(){
			return this.$store.getters.isAuth;
		}
	}, 
    methods: {
        isRouterLoading(){
            return this.$store.getters['isRouterLoading'];
        },
        cancel(){
            this.logoutDialog = false;
			this.kartDialog = false;       
            this.signinDialog = false;     
        },
        showKartDialog(){
			this.kartDialog = true;
		},
        showSigninDialog(){
			this.signinDialog = true;
		}
    }
}
</script>

<style scoped>
button {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(82, 150, 86);
}
html {
  scroll-behavior: smooth;
}
</style>