<template>
	<teleport to="body">
		<transition name="backdrop">
			<div v-if="show" @click="tryClose" class="backdrop"></div>			
		</transition>
		<transition name="dialog">
			<dialog open v-if="show" style="overflow-y:auto">
				<header>Kart <h4 @click="tryClose" class="float-end"><i class="bi bi-x-lg"></i></h4></header>
				<base-spinner v-if="isLoading"></base-spinner>
				<section v-else style="">
					<div class="" >
						<div v-if="!empty" class="card mb-4 border-0">
							<div class="card-body p-2 border-0">
								<div class="row border mt-3 p-2 d-md-none" v-for="cart in carts" :key="cart.id">
									<div class="col-4" style="display:flex;align-items:center">
										<p>{{cart.product.name}} <br><br>{{cart.cost.category}}</p>
									</div>
									<div class="col-3">
										<button class="btn btn-primary my-2" v-on:click="updateCart(cart.id, cart.quantity-1)" style="width:50px">
											<h2>-</h2>
										</button>
										<input min="1" name="quantity"  v-model=cart.quantity class="form-control" style="width:50px"/>
										<button class="btn btn-primary my-2" v-on:click="updateCart(cart.id, cart.quantity+1)" style="width:50px">
											<h2>+</h2>
										</button>
									</div>
									<div class="col-3" style="display:flex;align-items:center">
										<p style="font-size:1.5em">
											<strong>₹ {{cart.cost.cost * cart.quantity}}</strong>
										</p>
									</div>
									<button type="button" class="col-2 btn btn-danger btn-sm btn-block" v-on:click="deleteCart(cart.id)" >
										<i class="bi bi-trash-fill"></i>
									</button>											
								</div>
								<div class="row border mt-3 p-2 d-none d-md-flex" v-for="cart in carts" :key="cart.id">
									<div class="col-3">									
										<p><strong>{{cart.product.name}}</strong></p>
										<p>{{cart.cost.category}}</p>													
									</div>
									<div class="col-6">									
										<div class="d-flex p-3">
											<button class="btn btn-primary px-3 me-2" v-on:click="updateCart(cart.id, cart.quantity-1)">
												<h2>-</h2>
											</button>
											<input min="1" name="quantity"  v-model=cart.quantity class="form-control" />												
											<button class="btn btn-primary px-3 ms-2" v-on:click="updateCart(cart.id, cart.quantity+1)">
												<h2>+</h2>
											</button>
										</div>																														
									</div>
									<div class="col-2">
										<p class="my-4 text-center">
											<strong>₹ {{cart.cost.cost * cart.quantity}}</strong>
										</p>
									</div>
									<button type="button" class="col-1 btn btn-danger btn-sm btn-block" v-on:click="deleteCart(cart.id)" >
										<i class="bi bi-trash-fill"></i>
									</button>											
								</div>	
							</div>
							<div>
								<h5 class="float-end mx-4 mt-3">Total : ₹ {{total}}</h5>
							</div>
							<div class="card-footer">
								<a href="/checkout" class="btn btn-primary btn-lg float-end mt-3">Checkout</a>
							</div>
						</div>
						<div v-else>
							Kart Empty!!
						</div>								
					</div>
				</section>
			</dialog>
		</transition>
	</teleport>
</template>

<script>
export default {
	props: {
		show: {
            type: Boolean,
            required: true,
        }
	},
	emits: ['cancel'],
	data(){
		return {
			isLoading : false,
		}
	},
	methods: {
		tryClose() {
            if (this.fixed) {
                return;
            }
            this.$emit('cancel');
        },
		async loadCarts(){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('carts/loadCarts');
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}				
		},
		async updateCart(cartId, newCount){
			console.log(newCount);
			if(newCount > 0){
				if(this.isAuth){		
					this.isLoading = true;
					try {
						await this.$store.dispatch('carts/updateCart',{
							cartId,
							newCount
						});
						this.isLoading = false;
					}
					catch(error){
						console.log(error);
					}	
				}	
			}
			else{
				if(this.isAuth){				
					this.isLoading = true;
					try {
						await this.$store.dispatch('carts/deleteCart',{
							cartId
						});
						this.isLoading = false;
					}
					catch(error){
						console.log(error);
					}	
				}	
			}
		},
		async deleteCart(cartId){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('carts/deleteCart',{
						cartId
					});
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}				
		},
		getSum(total, item){
			return total + (item.cost.cost * item.quantity);
		}
	},
	computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},
		carts() {
			return this.$store.getters['carts/carts'];
		},
		total(){
			var total = 0;
			var sumWithTotal = this.$store.getters['carts/carts'].reduce(this.getSum,total);
			return sumWithTotal;
		},
		empty(){
			return !(this.total > 0);
		}
	},
	created(){
		this.loadCarts();
	}
}
</script>

<style scoped>
a {
	color : white !important;
	text-decoration: none;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
}

dialog {
    position: fixed;
    top: 1vh;
    width: 100%;
	z-index: 100;
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: 90%;
    background-color: white;
}

header {
    background-color: #3a0061;
    color: white;
    width: 100%;
    padding: 1rem;
}

header h2 {
    margin: 0;
}

section {
    padding: 1rem;
}

menu {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

@media (min-width: 768px) {
    dialog {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}

.dialog-enter-from,
.dialog-leave-to {
    opacity: 0;
    transform: scale(0.8);
}

.dialog-enter-active {
    transition: all 0.3s ease-in;
}

.dialog-leave-active {
    transition: all 0.3s ease-out;
}

.dialog-enter-to,
.dialog-leave-from {
    opacity: 1;
    transform: scale(1);
}

.backdrop-enter-from,
.backdrop-leave-to {
    opacity: 0;
}

.backdrop-enter-active {
    transition: all 0.3s ease-in;
}

.backdrop-leave-active {
    transition: all 0.3s ease-out;
}

.backdrop-enter-to,
.backdrop-leave-from {
    opacity: 1;
}
</style>