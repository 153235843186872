<template>
	<div>
		<base-spinner v-if="isLoading"></base-spinner>
		<div v-else class="left-right mt-3">
			<h3>Comments</h3>
			<p>{{ comments.length }} Comments</p>
		</div>
		<div class="container" v-for="comment in comments" :key=comment.id>
			<hr>
			<div class="left-right">
				<h6>{{ comment.emailOrPhone }}</h6>				
				<button v-if="comment.emailOrPhone == username" type="submit" class="btn btn-danger" v-on:click="deleteComment(comment.id)">Delete</button>
				<p v-else>{{getxDaysAgo(comment.timestamp) != 0 ? getxDaysAgo(comment.timestamp) + ' days ago' : 'Today'}}</p>
			</div>		
			<p>{{ comment.comment }}</p>
		</div>
		<hr>
		<div v-if="isAuth" class="container my-3">
			<label class="form-label">Add comment</label>
			<input class="form-control" v-model="comment">
			<button type="submit" class="btn btn-primary btn-lg mt-3" v-on:click="addComment">Submit</button>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			comment : null,
			isLoading : false
		}
	},
	props : ['comments','productId'],
	methods: {
		getxDaysAgo(timestamp){
			const oneDay = 24 * 60 * 60 * 1000;
			const timestampDate = new Date(timestamp);
			const today = Date.now();
			return Math.round(Math.abs((timestampDate - today) / oneDay));
		},
		async addComment(){
			try{
				this.isLoading = true;
				if(this.isAuth){
					await this.$store.dispatch('products/addComment',{
						productId : this.productId,
						comment : this.comment
					});					
				}
				this.isLoading = false;
				this.comment = '';
			}
			catch(error){
				console.log(error);
			}
		},
		async deleteComment(id){
			try{
				this.isLoading = true;
				if(this.isAuth){
					await this.$store.dispatch('products/deleteComment',{						
						commentId : id
					});					
				}
				this.isLoading = false;
				this.comment = '';
			}
			catch(error){
				console.log(error);
			}
		}
	},
	computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},
		username(){
			if(this.isAuth){
				return '';
			}
			else{
				return this.$store.getters.username;
			}
		}
	},
}
</script>

<style scoped>
.left-right {
	display: flex;
	justify-content:space-between;
}
</style>