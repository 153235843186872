<template>
	<base-spinner v-if="isLoading"></base-spinner>
    <div v-else class="container mb-3 min-vh-100">
        <div class="d-flex mb-2 mt-4">
            <h3 class="text-black">Cart</h3>                    
        </div>        
        <div class="row justify-content-center">
            <div class="col-md-7 col-lg-7 col-xl-7">
                <w-card :image="cart['product']['Images'][0]['src']" :image-props=imageProps v-for="cart in carts" :key="cart.id" class="mt-3">
					<p class="lead fw-normal mb-2">{{cart.product.name}}</p>
                    <p>{{cart.cost.category}}</p>
					<h6 class="card-subtitle mt-1">{{ subtitle }}</h6>
					<div class="d-flex justify-content-between align-items-center">
						<button class="btn btn-primary px-3 me-2" v-on:click="updateCart(cart.id, cart.quantity-1)">
							<h2>-</h2>
						</button>
						<input min="1" name="quantity" type="number" v-model=cart.quantity class="form-control form-control-lg text-center" />
						<button class="btn btn-primary px-3 ms-2" v-on:click="updateCart(cart.id, cart.quantity+1)">
							<h2>+</h2>
						</button>
					</div>
					<h5 class="mt-3">₹ {{cart.cost.cost * cart.quantity}}</h5>
					<a class="btn btn-sm btn-danger" v-on:click="deleteCart(cart.id)" >
						Remove
					</a>
				</w-card>
            </div>
            <div class="col-md-5 col-lg-5 col-xl-5 mt-3">
                <div class="card">
                    <div class="card-body d-flex">
                        <input type="text" placeholder="Coupon code" class="form-control form-control-lg" v-model="code"/>                                
                        <button type="button" class="btn btn-outline-warning btn-lg mx-2" v-on:click="checkCode()">Apply</button>
                    </div>
                </div>
                <div class="card mt-4">
                    <Address @chosenAddressChange="setChosenAddress"></Address>
                </div>
                <div class="card mt-4">
                    <h6 class="text-black m-3">Total</h6>    
                    <div v-if="discount == 0.0" class="card-body d-flex flex-col align-items-start">
                        <h4>₹ {{total}}</h4>
                    </div>
					<div v-else class="card-body">
						<p class="h4">₹ {{total - (total*discount)}} <span class="h6">{{total}}</span></p>						
					</div>
                </div>
                <div class="card mt-4">
                    <button type="button" class="btn btn-lg btn-primary" v-on:click="createOrder">Proceed to pay</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Address from '@/components/Checkout/Address.vue';
import ProductLoader from '@/components/UI/ProductLoader.vue';
import { useToast } from 'vue-toastification'
import store from '../../store';
import router from '../../router';
export default {
    data(){
		return {
			isLoading : false,
			chosenAddressId: null,
			toast: null,
			code: '',
			imageProps : {
				'lazy'  : true,
				'ratio' : 2/3
			},
			discount: 0.0
		}
	},
	components: {
		Address,
		ProductLoader
	},
    methods: {
		async loadCarts(){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('carts/loadCarts');
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}				
		},
		async updateCart(cartId, newCount){
			if(newCount > 0){
				if(this.isAuth){				
					this.isLoading = true;
					try {
						await this.$store.dispatch('carts/updateCart',{
							cartId,
							newCount
						});
						this.isLoading = false;
					}
					catch(error){
						console.log(error);
					}	
				}	
			}
			else{
				if(this.isAuth){				
					this.isLoading = true;
					try {
						await this.$store.dispatch('carts/deleteCart',{
							cartId
						});
						this.isLoading = false;
					}
					catch(error){
						console.log(error);
					}	
				}	
			}				
		},
		async deleteCart(cartId){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('carts/deleteCart',{
						cartId
					});
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}				
		},
		getSum(total, item){
			return total + (item.cost.cost * item.quantity);
		},
		setChosenAddress(addressId){			
			this.chosenAddressId = addressId.chosenAddressId;
		},
		async createOrder(){
			console.log(this.chosenAddressId);
			if(this.chosenAddressId){
				this.isLoading = true;
				try {
					var response = await this.$store.dispatch('carts/createOrder',{
						addressId : this.chosenAddressId,
						discountCode: this.code
					});				
					var orderId = response.data["orderId"];
					var amount = response.data["amount"] * 100;					
					var options = {
						"key": process.env.VUE_APP_RAZORPAY_KEY,
						"amount": amount, 
						"currency": "INR",
						"order_id": orderId, 
						"handler": async function (response){
							var data = {
								razorpay_payment_id : response.razorpay_payment_id,
								razorpay_order_id : response.razorpay_order_id,
								razorpay_signature : response.razorpay_signature
							};
							var response = await store.dispatch('carts/capturePayment',data);
							this.isLoading = false;
							if(response.status == 200 && response.data['message'] == 'Message sent'){
								router.push('/');
							}
						},
						"notes": {
							"address": "Garden Snacks - Gobi"
						}
					};
					var rzp1 = new Razorpay(options);
					rzp1.on('payment.failed', function (response){
						
					});
					rzp1.open();
				}
				catch(error){
					console.log(error);
				}	
			}			
		},
		async checkCode(){
			this.isLoading = true;
			var response = await this.$store.dispatch('carts/checkDiscount',{
				code : this.code
			});
			console.log(response.data['message']);
			if(response.data['message'] == 'Discount code valid'){
				this.discount = response.data['percent']
				this.toast.success("Code valid");
			}
			else{
				this.toast.error(response.data['message']);
			}
			this.isLoading = false;
		}
	},
    computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},
		username(){
			return this.$store.getters.username;
		},
		carts() {
			return this.$store.getters['carts/carts'];
		},
		total(){
			var total = 0;
			var sumWithTotal = this.$store.getters['carts/carts'].reduce(this.getSum,total);
			return sumWithTotal;
		}
	},
    created(){
		this.loadCarts();
		this.toast = useToast()
	},
	watch: {
		total(newTotal, oldTotal){
			if(newTotal <= 0){
				this.$router.push('/');
			}
		}
	}
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
  	margin: 0;
}

input[type=number] {
  	-moz-appearance: textfield;
}
span {
  text-decoration: underline;
  text-underline-offset: -40%; /* <-- this */
  text-decoration-skip-ink: none;
}
</style>