<template>
	<Product class="mb-5"></Product>
</template>

<script>
import Product from '@/components/Product/Product.vue';
export default {
	components: {
		Product
	}
}
</script>

<style>

</style>