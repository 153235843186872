<template>	
	<teleport to="body">
		<transition name="backdrop">
			<div v-if="show" @click="tryClose" class="backdrop"></div>			
		</transition>
		<transition name="dialog">
			<dialog open v-if="show">
				<header>Signin </header>
				<section class="">
					<div class="">
						<div class="card mb-4 border-0">
							<div class="card-body p-2 border-0">
								<base-spinner v-if="isLoading"></base-spinner>
								<!-- <div v-else class="modal-body">
									<div class="mb-3">
										<label for="emailOrPhone" class="form-label">Enter email</label>
										<input class="form-control" v-model.trim="emailOrPhone" type="email">
									</div>
									<div v-if="otpSent" class="mb-3">
										<label for="otp" class="form-label">Enter otp</label>
										<input maxlength="6" minlength="6" class="form-control" v-model.trim="otp">
									</div>							
									<div class="text-center">
										<base-button type="button" @click="signin">
											<h6 v-if="otpSent">Signin</h6>
											<h6 v-else>Send OTP</h6>
										</base-button>
									</div>
								</div> -->
								<div v-else class="container my-2" align="center">
									<!-- <hr> -->
									<a :href=googleSignInUrl><img src="~@/assets/google.png" style="height:60%;width:50%" alt=""></a>
								</div>																		
							</div>							
						</div>						
					</div>
				</section>
			</dialog>
		</transition>
	</teleport>
</template>

<script>
export default {
	created(){
		console.log(this.googleSignInUrl);
	},
	props: {
		show: {
            type: Boolean,
            required: true,
        }
	},
	emits: ['cancel'],
	data(){
		return {
			emailOrPhone: null,
			otp: null,
			otpSent: false,
			modalElement: null,
			isLoading : false
		}
	},
	computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},
		googleSignInUrl(){
			return process.env.VUE_APP_API+"/google";
		}
	},
	methods: {
		tryClose() {
            if (this.fixed) {
                return;
            }
            this.$emit('cancel');
			this.otpSent = false;
        },
		async signin(){
			try{
				this.isLoading = true;
				if(this.otpSent){
					var success = await this.$store.dispatch('verifySignin',{
						emailOrPhone : this.emailOrPhone,
						otp : this.otp
					});
					if(success){
						this.otpSent = false;
						this.$emit('cancel');
					}					
				}
				else{
					var success = await this.$store.dispatch('signin',{
						emailOrPhone : this.emailOrPhone
					});
					if(success){
						this.otpSent = true;						
					}					
				}
				this.isLoading = false;
			}
			catch(error){
				console.log(error);
			}			
		}
	}
}
</script>

<style scoped>
a {
	color : white !important;
	text-decoration: none;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
}

dialog {
    position: fixed;
    top: 1vh;
    width: 100%;
	z-index: 100;
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: white;
}

header {
    background-color: rgb(82, 150, 86);
    color: white;
    width: 100%;
    padding: 1rem;
}

header h2 {
    margin: 0;
}

section {
    padding: 1rem;
}

menu {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

@media (min-width: 768px) {
    dialog {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}

.dialog-enter-from,
.dialog-leave-to {
    opacity: 0;
    transform: scale(0.8);
}

.dialog-enter-active {
    transition: all 0.3s ease-in;
}

.dialog-leave-active {
    transition: all 0.3s ease-out;
}

.dialog-enter-to,
.dialog-leave-from {
    opacity: 1;
    transform: scale(1);
}

.backdrop-enter-from,
.backdrop-leave-to {
    opacity: 0;
}

.backdrop-enter-active {
    transition: all 0.3s ease-in;
}

.backdrop-leave-active {
    transition: all 0.3s ease-out;
}

.backdrop-enter-to,
.backdrop-leave-from {
    opacity: 1;
}
</style>