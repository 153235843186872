<template>
	<div>
		<nav class="navbar navbar-dark bg-dark navbar-expand-lg ">
			<div class="container container-fluid">
				<a class="navbar-brand" href="/" style="max-width: 30%; max-height:100px">
					<img src="~@/assets/garden.png" class="img-fluid">
				</a>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navContent" aria-controls="navContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navContent">
					<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
						<li class="nav-item">
							<a class="nav-link active" href="/">Home</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="/products">Products</a>
						</li>
						<li class="nav-item">          				
							<a v-if="isAuth" class="nav-link active" href="/profile">Orders</a>
							<a v-else class="nav-link active" v-on:click="showSigninDialog">Signin</a>
						</li>
						<li v-if="isAuth" class="nav-item">
							<a class="nav-link active" v-on:click="showKartDialog">Kart</a>
						</li>
						<!-- <li v-if="isAuth" class="nav-item float-end ms-md-auto">
							<a href="" class="nav-link active">
								Welcome, {{ username }}
							</a>						
						</li> -->
						<li v-if="isAuth" class="nav-item float-end ms-md-auto">
							<a class="nav-link active" v-on:click="showLogoutDialog">Logout</a>						
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<Kart :show="kartDialog" @cancel="cancel"></Kart >
		<Signin :show="signinDialog" @cancel="cancel"></Signin>
		<base-dialog :show="logoutDialog" title="Logout" message="Do you want to logout?" @close="logout" @cancel="cancel"></base-dialog>
	</div>
</template>

<script>
import Signin from '@/components/Signin.vue';
import Kart from '@/components/Kart.vue';
import Profile from '@/components/Profile/Profile.vue';
export default {
	components: {
		Signin,
		Kart,
		Profile
	},
	data(){
		return{
			logoutDialog : false,
			kartDialog : false,
			signinDialog: false
		}
	},
	computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},
		username(){
			if(this.isAuth){
				return '';
			}
			else{
				return this.$store.getters.username;
			}
		}
	},
	methods: {
		logout(){
			this.logoutDialog = false;
			this.$store.dispatch('logout');
			this.$router.push('/');
		},
		cancel(){
            this.logoutDialog = false;
			this.kartDialog = false;
			this.signinDialog = false;
        },
		showLogoutDialog(){
			this.logoutDialog = true;
		},
		showKartDialog(){
			this.kartDialog = true;
		},
		showSigninDialog(){
			this.signinDialog = true;
		}
	}
}
</script>

<style scoped>
p {
	color :white
}
</style>
