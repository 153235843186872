<template>
	<Grid></Grid>
</template>

<script>
import Grid from '@/components/Products/Grid.vue';
export default {
	components: {
		Grid
	}
}
</script>

<style>

</style>