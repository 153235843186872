const axios = require('axios').default;
const api_url = process.env.VUE_APP_API;

export default {
	namespaced: true,
	state() {
		return {
			products: [],
			product: null
		}
	},
	mutations: {
		setProducts(state, payload){
			state.products = payload;
		},
		setProduct(state, payload){
			state.product = payload;
		},
		setComment(state, payload){
			state.product.comments.push(payload)
		},
		deleteComment(state, payload){
			state.product.comments = state.product.comments.filter((comment) => {
				return comment.id != payload
			});			
		}
	},
	actions: {
		async loadProducts(context, { source }) {
			try {
				const response = await axios.get(api_url + '/product?sid='+source);
				const products = [];
				for (const item in response.data['products']){
					const product = {
						id : response.data['products'][item]['id'],
						name : response.data['products'][item]['name'],
						subtitle : response.data['products'][item]['subtitle'],
						image : response.data['products'][item]['Images'][0]['src']
					};
					products.push(product);
				}
				context.commit('setProducts', products);
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async loadProduct(context, { productId }) {
			try {
				const response = await axios.get(api_url + '/product/' + productId);
				var product = {
					id : response.data['product']['id'],
					name : response.data['product']['name'],
					subtitle : response.data['product']['subtitle'],
					available : response.data['product']['available'],
					image : response.data['product']['Images'][0]['src'],
					description : response.data['product']['description']
				};
				product.costs = [];
				product.comments = [];
				for (var item in response.data['product']['Costs']){
					var cost = {
						id : response.data['product']['Costs'][item]['id'],
						category : response.data['product']['Costs'][item]['category'],
						cost : response.data['product']['Costs'][item]['cost']
					}
					product.costs.push(cost);
				}
				for (var item in response.data['product']['Comments']){
					var comment = {
						id : response.data['product']['Comments'][item]['id'],
						comment : response.data['product']['Comments'][item]['comment'],
						userId : response.data['product']['Comments'][item]['userId'],
						emailOrPhone : response.data['product']['Comments'][item]['User']['emailOrPhone'],
						timestamp : response.data['product']['Comments'][item]['timestamp']
					}
					product.comments.push(comment);
				}
				context.commit('setProduct', product);
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async addComment(context, { productId, comment }) {
			try {
				const response = await axios.post(api_url + '/comment', {
					comment,
					productId,
					rating : 4
				},{
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				console.log(response);
				context.commit('setComment', {
					id : response.data['comment']['id'],
					comment : comment,
					userId : this.state.userId,
					emailOrPhone : this.state.username,
					timestamp : Date.now()
				});
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async deleteComment(context, { commentId }) {
			try {
				const response = await axios.delete(api_url + '/comment/' + commentId, {
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				context.commit('deleteComment', commentId);
			}
			catch(error){
				console.log(error);
				throw error;
			}
		}
	},
	getters: {
		products(state, getters, rootState, rootGetters) {
			return state.products
		},
		product(state, getters, rootState, rootGetters) {
			return state.product
		}
	}
}