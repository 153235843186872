<template>
	<div>
		<div class="row">
			<div class="col-8">
				<h6 class="text-black m-3">Choose Address</h6>	
			</div>
			<div class="col-4">
				<button class="btn btn-lg ms-auto float-end" v-on:click="newAddressModal.show()">
					<h2 class="bi bi-plus-circle-fill" style="color:blue"></h2>
				</button>
			</div>
		</div>				
		<div class="card-body d-flex">
            <select class="form-select form-select-lg" v-model="chosenAddress" v-on:change="changeChosenAddress">
				<option v-for="address in addresses" :key=address.id :value=address>{{address.door +", "+ address.street +" - "+ address.city +" -"+ address.pincode}}</option>
			</select>			
            <div class="modal fade" id="newAddressModal" ref="newAddressModal">
				<div class="modal-dialog modal-dialog-scrollable">
					<div class="modal-content p-3">
						<div class="modal-header">
							<h3>New Address</h3>
							<!-- <button type="button" class="btn-close" v-on:click="newAddressModal.hide()"></button> -->
						</div>
						<div class="modal-body">
							<div class="mb-3">
								<label for="" class="form-label">Name*</label>
								<input class="form-control" v-model.trim="name">
							</div>
							<div class="mb-3">
								<label for="" class="form-label">Door*</label>
								<input class="form-control" v-model.trim="door">
							</div>
							<div class="mb-3">
								<label for="" class="form-label">Street/Apartment*</label>
								<input class="form-control" v-model.trim="street">
							</div>
							<div class="mb-3">
								<label for="" class="form-label">Landmark</label>
								<input class="form-control" v-model.trim="landmark">
							</div>
							<div class="mb-3">
								<label for="" class="form-label">City*</label>
								<!-- <select class="form-control" v-model.trim="city" id="">
									<option v-for="city in cities" :key="city" :value=city>{{city}}</option>
								</select>-->
								<input class="form-control" v-model.trim="city">
							</div>
							<div class="mb-3">
								<label for="" class="form-label">State*</label>
								<select class="form-control" v-model.trim="state" id="">
									<option value="TamilNadu">TamilNadu</option>
									<option value="Kerala">Kerala</option>
									<option value="Andhra Pradesh">Andhra Pradesh</option>
									<option value="Telengana">Telengana</option>
									<option value="Karnataka">Karnataka</option>
									<option value="Puducherry">Puducherry</option>
									<option value="Maharashtra">Maharashtra</option>
								</select>
							</div>
							<div class="mb-3">
								<label for="" class="form-label">Pincode*</label>
								<input type="text" maxlength="6" class="form-control" v-model.trim="pincode">
							</div>
							<div class="mb-3">
								<label for="" class="form-label">Phone*</label>
								<input class="form-control" maxlength="10" v-model.trim="phone">
							</div>
							<div class="text-center">
								<base-button type="button mt-3" v-on:click="addAddress">
									<h6>Add Address</h6>
								</base-button>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>		
	</div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
	emits: ['chosenAddressChange'],
	data(){
		return {
			cities : ['Gobi','Erode','Tirupur','Coimbatore'],
			newAddressModal: '',
			door : '',
			street : '',
			landmark : '',
			city : '',
			state: '',
			pincode :'',
			chosenAddress :'',
			phone: '',
			name: ''
		}
	},
	computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},
		addresses() {
			return this.$store.getters['addresses'];
		},
	},
	methods: {
		async loadAddresses(){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('loadAddresses');
					this.isLoading = false;
					if(this.addresses[0]){
						this.chosenAddress = this.addresses[0];
						console.log(this.chosenAddress.id);
						this.$emit('chosenAddressChange',{
							chosenAddressId : this.chosenAddress.id
						});
					}		
					else{
						this.newAddressModal.show();
					}
				}
				catch(error){
					console.log(error);
				}	
			}				
		},
		async addAddress(){
			if(this.isAuth){
				this.isLoading = true;
				try {
					var success = await this.$store.dispatch('addAddress', {
						door : this.door,
						street : this.street,
						landmar : this.landmar,
						city : this.city,
						state : this.state,
						pincode : this.pincode,
						phone: this.phone,
						name: this.name
					});
					this.isLoading = false;
					if(success){
						this.newAddressModal.hide();
						this.chosenAddress = this.addresses[this.addresses.length-1];
						this.$emit('chosenAddressChange',{
							chosenAddressId : this.chosenAddress.id
						});
					}
					this.door = '';
					this.street = '';
					this.landmark = '';
					this.city = '';
					this.state = '';
					this.pincode = '';
					this.phone = '';
					this.name = '';					
				}
				catch(error){
					console.log(error);
				}	
			}			
		},
		changeChosenAddress(event){
			this.$emit('chosenAddressChange',{
				chosenAddressId : this.chosenAddress.id
			});
		}
	},
	mounted(){
		this.newAddressModal = new Modal(this.$refs.newAddressModal);
		this.loadAddresses();		
	}
}
</script>

<style scoped>
.modal{
    pointer-events: none;
}

.modal-dialog{
    pointer-events: all;
}

</style>
