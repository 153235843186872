<template>
	<div class="my-5">
		<base-spinner v-if="isLoading"></base-spinner>
		<div v-else class="container vertical-center">
			<div class="card">
				<div class="row">
					<div class="col-md-12" align="center">
						<w-image :src=product.image :ratio=5/8></w-image>
						<!-- <img :src=product.image class="img-fluid p-3" alt=""> -->
					</div>
					<div class="col-md-12 p-2">
						<div class="card-body">
							<h3 class="card-title">{{ product.name }}</h3>
							<h5 class="card-subtitle mt-2">{{ product.subtitle }}</h5>
							<div class="mt-3">
								<h4>₹ {{selectedCost.cost}}</h4>
							</div>
							<div class="mt-3">
								{{ product.description }}
							</div>
							<div class="mt-4">
								<p>Quantity</p>
								<div class="row">
									<div class="col-6">
										<select class="form-select form-select-lg" v-model="selectedCost">
											<option disabled value="">Select option</option>
											<option v-for="cost in product.costs" :key=cost.id :value=cost>{{cost.category}}</option>									
										</select>	
									</div>
									<div class="col-6">
										<button v-if="product.available" class="btn btn-primary btn-lg" v-on:click="addCart">Add to cart</button>
										<button v-else class="btn btn-primary btn-lg" disabled>Sold out</button>
									</div>
								</div>								
							</div>							
						</div>					
					</div>				
				</div>
			</div>
			<Share :product=product></Share>
			<!-- <div class="card mt-3">
				<div class="card-body">
					<div class="tab-content" id="myTabContent">
						<Comments :comments=product.comments :productId=product.id></Comments>
					</div>
				</div>
			</div> -->
		</div>	
		<Signin :show="signinDialog" @cancel="cancel"></Signin>
	</div>
</template>

<script>
import Comments from '@/components/Product/Comments.vue'
import Share from '@/components/Product/Share.vue'
import Signin from '@/components/Signin.vue';
export default {
	props: ['productId'],
	data(){
		return {
			isLoading: false,
			error: null,
			selectedCost: null,
			signinDialog : false
		}
	},
	components: {
		Comments,
		Share,
		Signin
	},
	computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},		
		product(){
			return this.$store.getters['products/product'];
		}
	},
	methods: {
		async loadProduct(){
			this.isLoading = true;
			try {
				await this.$store.dispatch('products/loadProduct', {
					productId : this.productId
				});
				this.isLoading = false;
				this.selectedCost = this.product.costs[0];	
			}
			catch(error){
				console.log(error);
			}
		},
		showSigninDialog(){
			this.signinDialog = true;
		},
		async addCart(){
			if(this.isAuth){
				this.isLoading = true;
				try {
					console.log(this.productId);
					await this.$store.dispatch('carts/addCart', {
						productId : this.productId,
						costId : this.selectedCost.id
					});
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}
			else{
				this.showSigninDialog();
			}			
		}
	},
	created(){
		this.loadProduct();		
	}
}
</script>

<style scoped>
img {
	max-height: 600px;
	width: auto;
	display: block;
	margin: auto;
	background-image: url('~@/assets/loading.gif');
    background-repeat: no-repeat;
}

.vertical-center {
	margin-top: 2%;	
}
.card-subtitle {
	font-weight: 700;
}
</style>