<template>
	<w-card :image=image :image-props=imageProps>
		<h5>{{ name }}</h5>
		<h6 class="card-subtitle mt-1">{{ subtitle }}</h6>
		<a :href="/product/+id" class="btn btn-primary w-100 mt-3">View</a>
	</w-card>
</template>

<script>
export default {
	props: ['id','name','image','subtitle'],
	data() {
		return {
			imageProps : {
				'lazy'  : true,
				'ratio' : 2/3
			}
		}
	}
}
</script>

<style scoped>
.card-subtitle {
	font-weight: 700;
}
</style>