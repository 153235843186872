<template>
	<div class="container my-5">
		<div class="card mt-5">
  			<div class="card-body">
    			<h5 class="card-title">Our Products</h5>
				<div v-if="isLoading" class="row">
					<div v-for="index in 8" :key=index class="col-md-3 col-12">
						<ProductLoader></ProductLoader>
					</div>
				</div>
				<div v-else class="row">
					<div v-for="product in products" :key=product.id class="col-md-3 col-12 p-1">
						<Product :id=product.id :name=product.name :image=product.image :subtitle=product.subtitle></Product>
					</div>
				</div>				
  			</div>
		</div>
	</div>	
</template>

<script>
import Product from '@/components/Products/Product.vue';
import ProductLoader from '@/components/UI/ProductLoader.vue';
export default {
	components: {
		Product,
		ProductLoader
	},
	data() {
		return {
			isLoading: false,
			error: null
		}
	},
	computed: {
		products() {
			return this.$store.getters['products/products'];
		}
	},
	methods: {
		async loadProducts(){
			var source = 1;
			this.isLoading = true;
			try {
				await this.$store.dispatch('products/loadProducts', {
					source : source
				});
				this.isLoading = false;
			}
			catch(error){
				console.log(error);
			}
		}
	},
	created(){
		this.loadProducts();
	}
}
</script>

<style>

</style>