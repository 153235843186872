<template>
	<div class="table-responsive">
		<table class="table text-center table-borderless mt-4">
			<thead>
				<tr>
					<th scope="col">Quantity</th>
					<th scope="col">Cost</th>
					<th scope="col">Status</th>
					<th scope="col">Ordered On</th>
					<th scope="col">Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td scope="row">{{order['quantity']}} x {{order['cost']['category']}}</td>
					<td scope="row">₹ {{order['quantity']*order['cost']['cost']}}</td>
					<td scope="row">{{order['status']}}</td>
					<td scope="row">{{ order['date'] }}</td>
					<td scope="row"><button v-if="canCancel" v-on:click="cancel" class="btn btn-sm btn-danger float-right">Cancel</button></td>
				</tr>
			</tbody>
		</table>
	</div>	
</template>

<script>
export default {
	props : ['order'],
	emits: ['cancel'],
	computed: {
		canCancel(){
			if(this.order['status'] == 'cancelled' || this.order['status'] == 'delivered' ||  this.order['status'] == 'shipped'){
				return false;
			}
			return true;
		},
		cancel(){
            this.$emit('cancel');
        }
	}
}
</script>

<style scoped>
.order {
	max-width: 800px;	
}
</style>