<template>
	<div>
		<Banner></Banner>
		<Grid></Grid>
	</div>
</template>

<script>
import Banner from '@/components/Home/Banner.vue';
import Grid from '@/components/Home/Grid.vue';
export default {
	components : {
		Banner,
		Grid
	}
}
</script>

<style>
#text-center{ display:flex; flex-direction:column; justify-content:center;
  min-height:100vh; }
</style>