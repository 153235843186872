import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSocialSharing from 'vue-social-sharing';
import App from './App.vue'
import router from './router'
import store from './store'
import BaseSpinner from './components/UI/BaseSpinner';
import BaseButton from './components/UI/BaseButton';
import BaseDialog from './components/UI/BaseDialog';
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'

const app = createApp(App);

new WaveUI(app, {});

const options = {
	timeout: 2000,
};

app.use(store);
app.use(router);
app.use(Toast, options)
app.component('base-spinner',BaseSpinner);
app.component('base-button',BaseButton);
app.component('base-dialog',BaseDialog);
app.use(VueSocialSharing);
app.mount('#app');