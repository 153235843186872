<template>
	<Profile></Profile>
</template>

<script>
import Profile from '@/components/Profile/Profile.vue';
export default {
	components: {
		Profile
	}
}
</script>

<style>

</style>