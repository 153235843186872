import { createStore } from 'vuex';
import { useToast } from 'vue-toastification'
import createPersistedState from 'vuex-persistedstate';
import productsModule from './products';
import cartsModule from './carts';
const axios = require('axios').default;
const api_url = process.env.VUE_APP_API;

const toast = useToast()

export default createStore({
    modules: {
        products: productsModule,
        carts: cartsModule
    },
    state() {
        return {
            token: null,
            userId: null,
            username: null,
            addresses: [],
            orders: [],
            routerLoading: false,
            currentPath: '/'
        }
    },
    getters: {
        userId(state) {
            return state.userId
        },
        username(state) {
            return state.username
        },
        token(state) {
            return state.token
        },
        isAuth(state) {
            return (state.token != null) && (state.userId != null);
        },
        addresses(state) {
            return state.addresses;
        },
        orders(state) {
            return state.orders;
        },
        isRouterLoading(state) {
            return state.routerLoading;
        },
        currentPath(state){
            return state.currentPath;
        }
    },
    mutations: {
        setUser(state, { token, userId, username }) {
            state.token = token;
            state.userId = userId;
            state.username = username
        },
        setAddresses(state, addresses) {
            state.addresses = addresses;
        },
        setOrders(state, orders) {
            state.orders = orders;
        },
        cancelOrder(state, payload) {
            state.orders.find((order) => {
                return order.id == payload;
            }).status = 'cancelled';
        },
        addAddress(state, address) {
            state.addresses.push(address);
        },
        setRouterLoading(state, isLoading) {
            state.routerLoading = isLoading;
        },
        setCurrentPath(state, currentPath){
            state.currentPath = currentPath;
        }
    },
    actions: {
        async googleSignIn(context, { token, refresh, username }) {
            var response = '';
            try {
                response = await axios.get(api_url + '/verify', {
                    headers: {
                        'Authorization': token,
                        'Refresh': 'test'
                    }
                });
                if (response.status == '200'){
                    context.commit('setUser', {
                        token: token,
                        userId: response.data['user']['id'],
                        username: username
                    });
                    context.dispatch('carts/loadCarts');
                    toast.success("Signed in");
                    return true;   
                }                
            } catch (error) {
                error = { error }
                toast.error(error.error.response.data.message);
                return false;
            }                        
        },
        async verifySignin(context, { emailOrPhone, otp }) {
            var response = '';
            try {
                response = await axios.post(api_url + '/verifySignin', {
                    emailOrPhone,
                    otp
                });
                context.commit('setUser', {
                    token: response.data['token'],
                    userId: response.data['id'],
                    username: response.data['emailOrPhone']
                });
                context.dispatch('carts/loadCarts');
                toast.success("Signed in");
                return true;
            } catch (error) {
                console.log(error);
                error = { error }
                toast.error(error.error.response.data.message);
                return false;
            }
        },
        async signin(context, { emailOrPhone }) {
            try {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailOrPhone)) {
                    const response = await axios.post(api_url + '/signin', {
                        emailOrPhone
                    });
                    toast.success("OTP sent to your mail");
                    return true;
                } else {
                    toast.error("Please enter a valid email");
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        logout(context) {
            sessionStorage.clear();
            context.commit('setUser', {
                token: null,
                userId: null,
            });
            toast.success("Signed out");
            context.commit('carts/setCarts', null);
        },
        async loadAddresses(context) {
            try {
                const response = await axios.get(api_url + '/address', {
                    headers: {
                        'Authorization': this.state.token,
                        'Refresh': 'test'
                    }
                });
                const addresses = [];
                for (const item in response.data['user']['addresses']) {
                    const address = {
                        id: response.data['user']['addresses'][item]['id'],
                        door: response.data['user']['addresses'][item]['door'],
                        street: response.data['user']['addresses'][item]['street'],
                        landmark: response.data['user']['addresses'][item]['landmark'],
                        city: response.data['user']['addresses'][item]['city'],
                        state: response.data['user']['addresses'][item]['state'],
                        pincode: response.data['user']['addresses'][item]['pincode'],
                        phone: response.data['user']['addresses'][item]['phone'],
                        name: response.data['user']['addresses'][item]['name']
                    };
                    addresses.push(address);
                }
                context.commit('setAddresses', addresses);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async loadOrders(context) {
            try {
                const response = await axios.get(api_url + '/order', {
                    headers: {
                        'Authorization': this.state.token,
                        'Refresh': 'test'
                    }
                });
                const orders = [];
                for (const item in response.data['orders']) {
                    const order = {
                        id: response.data['orders'][item]['id'],
                        status: response.data['orders'][item]['status'],
                        quantity: response.data['orders'][item]['quantity'],
                        address: response.data['orders'][item]['Address'],
                        cost: response.data['orders'][item]['Cost'],
                        product: response.data['orders'][item]['Product'],
                        date: new Date(response.data['orders'][item]['createdAt']).toDateString()
                    };
                    orders.push(order);
                }
                context.commit('setOrders', orders);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async cancelOrder(context, { orderId }) {
            try {
                const response = await axios.delete(api_url + '/order/' + orderId, {
                    headers: {
                        'Authorization': this.state.token,
                        'Refresh': 'test'
                    }
                });
                context.commit('cancelOrder', orderId);
                toast.success("Order cancelled");
                return response;
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async addAddress(context, { door, street, landmark, city, state, pincode, phone, name }) {
            try {
                if(door != '' && street != '' && city !='' && pincode != '' && phone != '' && name != ''){
                    const response = await axios.post(api_url + '/address', {
                        door,
                        street,
                        landmark,
                        city,
                        state,
                        pincode,
                        phone,
                        name
                    }, {
                        headers: {
                            'Authorization': this.state.token,
                            'Refresh': 'test'
                        }
                    });
                    context.commit('addAddress', {
                        id: response.data['address']['id'],
                        door: response.data['address']['door'],
                        street: response.data['address']['street'],
                        landmark: response.data['address']['landmark'],
                        city: response.data['address']['city'],
                        state: response.data['address']['state'],
                        pincode: response.data['address']['pincode'],
                        phone: response.data['address']['phone'],
                        name: response.data['address']['name'],
                    });
                    toast.success("Address added");
                    return true;
                }                
                else{
                    toast.error("Please fill all the fields");
                    return false;
                }
            } catch (error) {
                console.log(error);
                throw error;                
            }
        },
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})