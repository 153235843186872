<template>
	<div class="card my-3">
  		<div class="card-body">
			<p class="card-text">{{address['name']}}</p>
    		<p class="card-text">{{address['door']}}, {{address['street']}}</p>
			<p class="card-text">{{address['city']}}</p>
			<p class="card-text">{{address['state']}}</p>    		
			<p class="card-text">{{address['pincode']}}</p>
			<p class="card-text">{{address['phone']}}</p>
  		</div>
	</div>
</template>

<script>
export default {
	props : ['address'],
	created(){
		console.log(this.address);
	},
	mounted(){
		console.log(this.address);
	}
}
</script>

<style scoped>
.order {
	max-width: 800px;	
}
</style>