<template>
	<div class="container my-3" id="grid">
		<div class="card">
  			<div class="card-body">
    			<h5 class="card-title">Featured Products</h5>
				<div v-if="isLoading" class="row">
					<div v-for="index in 4" :key=index class="col-md-3 col-12">
						<ProductLoader></ProductLoader>
					</div>
				</div>
				<div v-else class="row">
					<div v-for="product in products.slice(0,8)" :key=product.id class="col-md-3 col-12 mt-4">
						<Product :id=product.id :name=product.name :image=product.image :subtitle=product.subtitle></Product>
					</div>
					<div class="mt-5" align="center">
						<h3>
							<a href="/products" style="text-decoration:none;color:black">Load more <i class="bi bi-arrow-down-circle"></i></a>
						</h3>						
					</div>
				</div>				
  			</div>
		</div>
	</div>	
</template>

<script>
import Product from '@/components/Home/Product.vue';
import ProductLoader from '@/components/UI/ProductLoader.vue';
export default {
	components: {
		Product,
		ProductLoader
	},
	data() {
		return {
			isLoading: false,
			error: null
		}
	},
	computed: {
		products() {
			return this.$store.getters['products/products'];
		}
	},	
	methods: {
		async loadProducts(){
			var source = 1;
			if(this.$route.query.sid != null){
				source = this.$route.query.sid;
			}
			this.isLoading = true;
			try {
				await this.$store.dispatch('products/loadProducts', {
					source : source
				});
				this.isLoading = false;
			}
			catch(error){
				console.log(error);
			}
		}
	},
	created(){
		this.loadProducts();
	}
}
</script>

<style>

</style>