<template>
	<div class="container min-vh-100 mt-4">
		<h1>Canellation and Refund Policy</h1>		
<p>Last updated: May 23, 2022</p>
<p>Thank you for shopping at Garden Snacks.</p>
<p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. </p>
<p>The following terms are applicable for any products that You purchased with Us.</p>
<h1>Interpretation and Definitions</h1>
<h2>Interpretation</h2>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h2>Definitions</h2>
<p>For the purposes of this Return and Refund Policy:</p>
<ul>
<li>
<p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Garden Food Products, 67/26, Varathan street, Gobi - Erode dt. 638452.</p>
</li>
<li>
<p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
</li>
<li>
<p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
</li>
<li>
<p><strong>Service</strong> refers to the Website.</p>
</li>
<li>
<p><strong>Website</strong> refers to Garden Snacks, accessible from <a href="gardensnacks.in" rel="external nofollow noopener" target="_blank">gardensnacks.in</a></p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>
<h1>Your Order Cancellation Rights</h1>
<p>You are entitled to cancel Your Order within 1 day without giving any reason for doing so.</p>
<p>The deadline for cancelling an Order is 1 day from the date on which you ordered the goods from the website. You can do so by going into the following webpage</p>
<ul>
<li>By visiting this page on our website: <a href="https://gardensnacks.in/profile" rel="external nofollow noopener" target="_blank">https://gardensnacks.in/profile</a></li>
</ul>
<p>We will reimburse You no later than 14 days from the day on which We receive the cancellation request. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
<h2>Contact Us</h2>
<p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
<ul>
<li>By sending a mail to : sales@gardensnacks.in</li>
<li>
	By coming in person to the address
	<address>
		67/26, Varathan Street,
		Gobi
		Erode dt,
		638452
		Tamilnadu
	</address>
</li>
</ul>
	</div>
</template>

<script>
export default {
	
}
</script>

<style>

</style>