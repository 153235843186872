<template>
    <component :is="component">
        <slot></slot>
    </component>
</template>

<script>
export default {
    computed: {
        component() {
            return 'button';
        },
    },
};
</script>

<style scoped>
button,
a {
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    font: inherit;
    background-color: rgb(82, 150, 86);
    border: 1px solid rgb(82, 150, 86);
    color: white;
    cursor: pointer;
    border-radius: 30px;
    margin-right: 0.5rem;
    display: inline-block;
}
a:hover,
a:active,
button:hover,
button:active {
    background-color: #270041;
    border-color: #270041;
}
.flat {
    background-color: transparent;
    color: rgb(82, 150, 86);
    border: none;
}
.outline {
    background-color: transparent;
    border-color: #270041;
    color: #270041;
}
.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
    background-color: #edd2ff;
}
</style>