import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Checkout from '../views/Checkout.vue';
import Product from '../views/Product.vue';
import Products from '../views/Products.vue';
import Profile from '../views/Profile.vue';
import Cancellation from '../views/Cancellation.vue';
import Privacy from '../views/Privacy';
import Terms from '../views/Terms';
import store from '@/store';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/products',
        name: 'Products',
        component: Products
    },
    {
        path: '/product/:productId',
        name: 'Product',
        props: true,
        component: Product
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            needAuth: true,
        }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
            needAuth: true,
        }
    },
    {
        path: '/cancellation',
        name: 'Cancellation',
        component: Cancellation
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async function(to, from, next) {
    store.commit('setRouterLoading', true);
    if(to.query && to.query.token && to.query.refresh && to.query.user){
        await store.dispatch('googleSignIn', {
            token : to.query.token,
            refresh : to.query.refresh,
            username : to.query.user
        }).then(() => {
            var lastPath = store.getters.currentPath;
            next(lastPath)
        });        
    }
    else{
        if (to.meta.needAuth && !store.getters.isAuth) {
            next('/');
        }
        else if(to.name == 'Checkout'){
            var total = 0;
            var sumWithTotal = store.getters['carts/carts'].reduce((total, item) => {
                return total + (item.cost.cost * item.quantity);
            },total);        
            if(sumWithTotal <= 0){
                next('/');
            }
            else{
                next();
            }
        }
        else {
            next();
        }
    }
});

router.afterEach(async function(to, from, next) {
    store.commit('setRouterLoading', false);
    store.commit('setCurrentPath',to.path);    
});

export default router
