const axios = require('axios').default;
const api_url = process.env.VUE_APP_API;
import { useToast } from 'vue-toastification'

const toast = useToast()

export default {
	namespaced: true,
	state() {
		return {
			carts: []			
		}
	},
	mutations: {
		setCarts(state, payload){
			state.carts = payload;
		},
		addCartItem(state, payload){
			state.carts.push(payload);
		},
		removeCartItem(state, payload){
			state.carts = state.carts.filter((cart) => {
				return cart.id != payload;
			});
		},
		updateCartItem(state, payload){
			let updatedCart = state.carts.find((cart) => {
				return cart.id == payload.cartId;
			});
			updatedCart.quantity = payload.newCount;
		}
	},
	actions: {
		async loadCarts(context) {
			try {
				const response = await axios.get(api_url + '/kart',{
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				const carts = [];
				for (const item in response.data['karts']){
					const cart = {
						id : response.data['karts'][item]['id'],
						quantity : response.data['karts'][item]['quantity'],
						product : response.data['karts'][item]['Product'],
						cost : response.data['karts'][item]['Cost'],						
					};
					carts.push(cart);
				}
				context.commit('setCarts', carts);
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async addCart(context, { productId, costId }) {
			try {
				const response = await axios.post(api_url + '/kart', {
					productId,
					costId,					
				},{
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				toast.success("Added to Cart");
				context.commit('addCartItem', {
					id : response.data['kart']['id'],
					quantity : response.data['kart']['quantity'],
					product : response.data['product'],
					cost : response.data['cost'],					
				});
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async deleteCart(context, { cartId }) {
			try {
				const response = await axios.delete(api_url + '/kart/' + cartId, {
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				toast.success("Removed from Cart");
				context.commit('removeCartItem', cartId);
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async updateCart(context, { cartId, newCount }) {
			try {
				const response = await axios.put(api_url + '/kart/' + cartId,{
					newCount
				}, {
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				context.commit('updateCartItem', {
					cartId,
					newCount
				});
				toast.success("Cart Updated");
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async createOrder(context, { addressId, discountCode}) {
			try {
				const response = await axios.post(api_url + '/order', {
					addressId,discountCode			
				},{
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});				
				return response;
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},		
		async capturePayment(context, { razorpay_payment_id, razorpay_order_id, razorpay_signature}) {
			try {
				const response = await axios.post(api_url + '/capturePayment', {
					razorpay_payment_id, razorpay_order_id, razorpay_signature				
				},{
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				toast.success("Payment Successful");
				return response;
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
		async checkDiscount(context, {code}) {
			try {
				const response = await axios.get(api_url + '/discount/'+code,{
					headers: {
						'Authorization' : this.state.token,
						'Refresh' : 'test'
					}
				});
				return response
			}
			catch(error){
				console.log(error);
				throw error;
			}
		},
	},
	getters: {
		carts(state, getters, rootState, rootGetters) {
			return state.carts
		}
	}
}