<template>	
    <footer class="text-white" style="background-color: #23242a;">		
        <div class="d-flex justify-content-between">
            <div class="row m-3 ">
				<div class="col-12 col-lg-3 mt-4" align="center">
					<img src="~@/assets/logo.png">
					<img src="~@/assets/msme.png" class="w-75 h-50 my-2">
				</div>
				<div class="col-12 col-lg-3">
					<div class="mt-4">
						<h1>Garden Snacks</h1>
						<p>Welcome to Garden Snacks online, a place where you can find a variety of traditional South Indian snacks, made with utmost care and hygiene. We strive to give our customers, a great satisfaction by choosing the highest quality of ingredients. All our products are meticulously made to have excellent taste without losing our long established flavors. We utilize our 17 years of vast experience to relish our customers with our crunchy snacks. Come, check out with our products!!!</p>
					</div>					
				</div>
				<div class="col-12 col-lg-3 mt-5" align="center">
					<img src="~@/assets/fssai.png">					
				</div>
				<div class="col-12 col-lg-3 mt-4">
					<div class="mb-1">
                        <i class="bi bi-pin-map"></i><span class="ms-2">67/26, Varathan Street, Gobi 638452</span>
                    </div>
                    <div class="mb-1">
                        <i class="bi bi bi-mailbox2"></i><span class="ms-2">sales@gardensnacks.in</span>
                    </div>
                    <div class="mb-1">
                        <i class="bi bi-telephone"></i><span class="ms-2">+91 9865859086</span>
                    </div>
					<hr style="color:white">
					<h5>Legal</h5>
					<div class="row">
						<div class="col-12"><a href="/terms">Terms and Conditions</a></div>
						<div class="col-12"><a href="/cancellation">Cancellation and Refund Policy</a></div>
						<div class="col-12"><a href="/privacy">Privacy Policy</a></div>
					</div>
					<hr style="color:white">
					<div align="center">
						<a class="btn btn-floating" href="https://www.facebook.com/people/Garden-Food-Products-Garden-Snacks/100081893383959/" target="_blank" role="button">
							<i class="bi bi-facebook"></i>
						</a>
						<a class="btn btn-floating" href="https://www.instagram.com/gardenfoodproducts/" target="_blank" role="button">
							<i class="bi bi-instagram"></i>
						</a>				
					</div>
				</div>				
            </div>
        </div>		
        <div class="text-center p-3 mt-4" style="background-color: rgba(0, 0, 0, 0.2);">			
			<div class="row">
				<div class="col-12">
					<p>Garden Snacks</p>					
				</div>				
			</div>			            
        </div>        
    </footer>
    
</template>

<script>
export default {

}
</script>

<style scoped>
i {
	font-size:1.8em
}
span {
	font-size: 1.2em;
}
a {
	color : white;
	text-decoration:  none;
}
</style>