<template>
	<div class="container mt-3">
		<div class="row card">
			<h3 class="mt-2">Share</h3>
			<div class="card-body row">
				<div class="col text-center text-center">
					<ShareNetwork network="WhatsApp" :url=url :title="`${product.name} by Garden Snacks`" :description="`${product.description}`">
						<h1><i class="bi bi-whatsapp" style="color:#25D366"></i></h1>	
					</ShareNetwork>	
				</div>
				<div class="col text-center">
					<ShareNetwork network="facebook" :url=url :title="`${product.name} by Garden Snacks`" :description="`${product.description}`">
						<h1><i class="bi bi-facebook"></i></h1>	
					</ShareNetwork>
				</div>
				<div class="col text-center">
					<ShareNetwork network="SMS" :url=url :title="`${product.name} by Garden Snacks`" :description="`${product.description}`">
						<h1><i class="bi bi-chat-dots" style="color:black"></i></h1>
					</ShareNetwork>
				</div>
				<div class="col text-center">
					<ShareNetwork network="Email" :url=url :title="`${product.name} by Garden Snacks`" :description="`${product.description}`">
						<h1><i class="bi bi-envelope" style="color:red"></i></h1>
					</ShareNetwork>
				</div>
				<div class="col text-center">
					<ShareNetwork network="Twitter" :url=url :title="`${product.name} by Garden Snacks`" :description="`${product.description}`">
						<h1><i class="bi bi-twitter"></i></h1>
					</ShareNetwork>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			url : process.env.VUE_APP_APP + '/product/' + this.product.id 
		}
	},
	props : ['product']
}
</script>

<style scoped>
</style>