<template>	
     <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel" style="height:30vh">
          <div class="carousel-inner">
               <div class="carousel-item active" id="banner">
                    <div class="row">
                         <div class="col-lg-3">
          
                         </div>
                         <div class="col-lg-3 p-5 mt-4">
                              <h1>Garden Snacks</h1>
                              <h5 class="mt-3">Traditional Snacks and Savories</h5>
                              <a href="#grid" class="btn btn-danger btn-lg mt-4">Shop now</a>
                         </div>
                         <div class="col-lg-3">
                              
                         </div>
                         <div class="col-lg-3">
                              
                         </div>
                    </div>                              
               </div>
               <!-- <div class="carousel-item bg-image" style="">
                    
               </div>                -->
          </div>
     </div>     
</template>

<script>
export default {

}
</script>

<style scoped>
.bg-image {
     height: 50vh;
	background-size:     100% 100%; 
     background-repeat:   no-repeat;
}

#banner {
	height: 30vh;
	background-color: rgb(82, 150, 86);
     background-size:     cover; 
     background-repeat:   no-repeat;
     background-position: center center;      
}
h1{
     font-size: 50px !important;
}
h5,h2,h1 {
     color : white
}

</style>